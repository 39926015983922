import React from "react"
import { ApolloProvider } from "@apollo/client"
import { client } from "./client"
import { AuthStoreProvider } from "@myaccount/website-service"

const enableMyAccount =
  (process.env.GATSBY_MYACCOUNT || null) === "0" ? false : true

export const wrapRootElement = ({ element }) => (
  <AuthStoreProvider>
    <div className={enableMyAccount ? "has-myacc" : "no-myacc"}>
      <ApolloProvider client={client}>{element}</ApolloProvider>
    </div>
  </AuthStoreProvider>
)
